import React from "react";

import Layout from "../../../components/hasuracon2022/Layout";
import Seo from "../../../components/seo";
import JobsHeroBanner from "../../../components/hasuracon2022/jobs/JobsHeroBanner";
import JobList from "../../../components/hasuracon2022/jobs/JobList";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022.png",
};

const canonicalUrl = "https://hasura.io/events/hasura-con-2022/jobs/";

const HasuraCon2022 = props => (
  <Layout location={props.location}>
    <Seo
      title="Jobs at HasuraCon'22"
      description="Find an amazing job for yourself at HasuraCon'22"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <JobsHeroBanner location={props.location} />
    <JobList />
  </Layout>
);

export default HasuraCon2022;
