import React, { useState } from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerHasCon from "../styles/StyledContainerHasCon";

import { StyledTitle1B, StyledSubTitle2 } from "../../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../../globals/designSystem";
import { textCenter } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

const StyledSectionWrapperPadd = styled(StyledSectionWrapper)`
  background-color: ${COLORS.black};
  overflow: hidden;
  position: relative;
  .readMore {
    z-index: 10;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    a {
      svg {
        transition: all 0.3s ease-in-out;
        -webkit-animation: up-down 1s infinite;
        -moz-animation: up-down 1s infinite;
        -o-animation: up-down 1s infinite;
        animation: up-down 1s infinite;
      }
    }
  }
  @keyframes up-down {
    0% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(5px);
    }
  }
  ${mq.between("md", "lg")} {
    .readMore {
      bottom: 30px;
    }
  }
  ${mq.below.md} {
    min-height: auto;
    .readMore {
      display: none;
    }
  }
`;

const StyledHeroBannerWrapper = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  .ptb70 {
    /* padding: 70px 0; */
  }
  &.scaleZero {
    transform: scale(0);
    height: 0;
    opacity: 0;
    padding-top: 0;
  }
  .mShow {
    display: none;
  }
  .buttonWrapper {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .hasConBtn {
      width: 330px;
      margin: 0 12px;
    }
    .registerBtn {
      &:hover {
        box-shadow: ${ELEVATION.l_3};
      }
    }
    .registerMobileBtn {
      display: none;
    }
  }
  .hasConFormWrapper {
    span {
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLORS.white};
      font-weight: 600;
      font-size: 20px;
      z-index: 1;
    }
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
    .ptb70 {
      /* padding: 40px 0; */
    }
    .mHide {
      display: none;
    }
    .mShow {
      display: block;
    }
    .mPaddBot {
      padding-top: 16px;
      padding-bottom: 200px;
      text-align: center;
    }
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      position: fixed;
      bottom: 28px;
      padding-bottom: 0;
      width: calc(100% - 64px);
      z-index: 10000;
      a {
        display: contents;
      }
      .hasConBtn {
        margin: 0 0;
        width: 100%;
      }
      .registerBtn {
        /* margin-bottom: 16px;
        display: none; */
      }
      .registerMobileBtn {
        display: block;
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
`;

const StyledHasConWrapper = styled.div`
  position: relative;
  .globe {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 0;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, -25%);
    video {
      display: block;
    }
    .globeVideo {
      z-index: -1;
      transform: scale(0.75);
      opacity: 0.5;
    }
  }
  .globePosLeft {
    transform: translate(-75%, -50%);
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
    .globe {
      transform: translate(-50%, -24%);
      display: block;
    }
    .globePosLeft {
      display: none;
    }
  }
`;

const JobsHeroBanner = props => {
  // eslint-disable-next-line
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  return (
    <StyledSectionWrapperPadd>
      <StyledContainerHasCon>
        <StyledHeroBannerWrapper>
          <div className="ptb70">
            <StyledSubTitle2
              className="mHide"
              variant="white"
              css={textCenter}
              paddingBottom="pb16"
            >
              The Hasura User Conference | Online | 28 - 30 June
            </StyledSubTitle2>
            <StyledSubTitle2
              className="mShow"
              variant="white"
              css={textCenter}
              paddingBottom="pb16"
            >
              June 28 - 30, 2022
            </StyledSubTitle2>
            <StyledTitle1B variant="white" css={textCenter}>
              GraphQL for Everyone
            </StyledTitle1B>
          </div>
        </StyledHeroBannerWrapper>
        <StyledHasConWrapper>
          <div className="globe globeTop">
            <video
              id="vid"
              controls={false}
              autoPlay
              muted
              loop
              preload
              playsInline
              className="globeVideo"
              onLoadedData={() => setIsVideoLoaded(true)}
              poster="https://graphql-engine-cdn.hasura.io/assets/main-site/hascon22_fallback_poster_min.png"
            >
              <source
                src="https://graphql-engine-cdn.hasura.io/assets/main-site/globe.mp4"
                type="video/mp4"
              />
              <img
                poster="https://graphql-engine-cdn.hasura.io/assets/main-site/hascon22_fallback_poster_min.png"
                alt="hasuracon_22_globe_fallback"
              />
            </video>
          </div>
        </StyledHasConWrapper>
      </StyledContainerHasCon>
    </StyledSectionWrapperPadd>
  );
};

export default JobsHeroBanner;
