import styled from "styled-components";
import { mq } from "../../../globals/utils";

export const StyledContainerHasCon = styled.section`
  clear: both;
  padding: 0 100px;
  ${mq.between("lg", "xl")} {
    padding: 0 50px;
  }
  ${mq.between("md", "lg")} {
    padding: 0 32px;
  }
  ${mq.below.md} {
    padding: 0 32px;
  }
`;

export default StyledContainerHasCon;
