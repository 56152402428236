import React, { useState } from "react";

import StyledFaqWrapper from "../StyledFaqWrapper";

import { StyledTitle4, StyledSubTitle2, StyledDesc2 } from "../StyledTypography";
import { textCenter } from "../CommonStyled";

const FaqWrapper = ({faqDetailState, title, titleCenter, hasConJob, titleSmall, eventsFaq}) => {
  const [openFaqIds, setOpenFaqIds] = useState({});
  const showAnswer = (id) => {
    const newOpenFaqIds = {...openFaqIds};
    if (typeof newOpenFaqIds[id] === "undefined") {
      newOpenFaqIds[id] = true;
    } else {
      newOpenFaqIds[id] = !newOpenFaqIds[id];
    }
    setOpenFaqIds(newOpenFaqIds);
  };
  const ListFaq = ({faqItem}) => {
    return (
      <div className={"faqListWrapper" + ((hasConJob) ? " hasConJob" : "") + ((eventsFaq) ? " eventsFaq" : "")}>
        <StyledDesc2
          role="button"
          tabIndex="0"
          onClick={() => showAnswer(faqItem.id)}
          variant={eventsFaq ? "grey_100" : "sky_90"}
          className={"faqQuestion" + ((openFaqIds[faqItem.id]) ? " activeQuestion" : "")}
        >
          <span>
            {faqItem.question}
          </span>
          <svg className={(openFaqIds[faqItem.id]) ? "rotateImg" : ""} width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8187 13.9348L17.9987 19.7548L12.1787 13.9348C11.5937 13.3498 10.6487 13.3498 10.0637 13.9348C9.47875 14.5198 9.47875 15.4648 10.0637 16.0498L16.9488 22.9348C17.5338 23.5198 18.4788 23.5198 19.0638 22.9348L25.9487 16.0498C26.5337 15.4648 26.5337 14.5198 25.9487 13.9348C25.3637 13.3648 24.4037 13.3498 23.8187 13.9348Z" fill="#005C8F"/>
          </svg>
        </StyledDesc2>
        <div className={"faqAnswer" + ((openFaqIds[faqItem.id]) ? " showAnswer" : " hideAnswer")}>
          {faqItem.answer}
        </div>
      </div>
    )
  }
  const faqAllDetails = faqDetailState.map((faqItem, index) => {
    return(
      <ListFaq key={index} faqItem={faqItem}/>
    )
  });
  return (
    <StyledFaqWrapper>
      {
        titleSmall ? (
          <StyledSubTitle2>{title}</StyledSubTitle2>
        ) : (
          <>
            {title ? <StyledTitle4 paddingBottom="pb16" variant="grey_100" css={titleCenter ? textCenter : null}>{title}</StyledTitle4> : null}
          </>
        )

      }
      {faqAllDetails}
    </StyledFaqWrapper>
  )
}

export default FaqWrapper;
