import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import FaqWrapper from "../../shared/FaqWrapper";
import { scrollToTop } from "../../common/Header/helper";
import { Icon } from "../../../globals/icons";
import {
  StyledTitle4,
  StyledSubTitle2,
  StyledDesc1,
  StyledDesc2,
} from "../../shared/StyledTypography";
// import { mq } from "../../../globals/utils";
// import { flexCenter } from "../../shared/CommonStyled";

const StyledJobsListWrapper = styled.div`
  .back {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .mr-6 {
      margin-right: 6px;
    }
  }
  .hasConJobTitle {
    padding-top: 40px;
  }
`;

const netlifyJobsState = [
  {
    id: "senior-backend-engineer-ruby",
    question: "Senior Backend Engineer (Ruby)",
    answer: (
      <StyledDesc2>
        As a Backend Engineer at Netlify, you'll work with a smart set of team members who are very
        motivated to keep learning and continuing to grow each other in a supportive way. We have a
        blameless culture where we solve problems as a team and everyone works together towards a
        common goal. There are different backend-oriented teams that your interests and experience
        could lead you into.
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Experience developing production level Ruby. Our main language is Ruby but we also have
            projects that span across multiple languages. We believe in picking the right language
            for the right problem.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            An extensive history of delivering product features & deploying services with a high
            level of comfort iterating on a system while it is constantly serving traffic. Our
            system is always on with demanding availability and throughput challenges.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />A familiarity of working with
            databases like MongoDB and SQL
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Some familiarity with and willingness to learn more about technologies like Docker,
            Kubernetes, Terraform, and Redis, as well as the Zuora billing platform and
            observability tools like Datadog and Humio
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Curiosity and openness to learning new technologies and best practices
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Passion for working in a collaborative environment, where you enjoy working with a
            diverse group of people with different expertise working across distributed locations
            around the world
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            The ability to thrive in an environment where experimentation and failure are celebrated
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://boards.greenhouse.io/netlify/jobs/5497858002"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
];

const hasuraJobsState = [
  {
    id: "engineering-manager-hasura-cloud-infrastructure-team-remote-us",
    question: "Engineering Manager - Hasura Cloud Infrastructure Team (Remote US)",
    answer: (
      <StyledDesc2>
        Hasura.io is looking for an experienced Engineering Manager to lead the team building the
        Hasura Cloud infrastructure. The Hasura Cloud platform gives customers a fully managed,
        production ready GraphQL API as a service to help build modern apps faster. The Cloud
        Infrastructure team is responsible for designing, building, maintaining and monitoring the
        infrastructure that Hasura Cloud runs on. This includes the API, dashboard, database
        infrastructure as well as Hasura, Gateway and the metrics/monitoring stack infrastructure.
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Lead one or more of the engineering teams building the Hasura Cloud Infrastructure
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Ensure the delivery of high quality and on-time software releases
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Contribute towards and facilitate decision making regarding architecture of various
            parts of the infrastructure
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Partner closely with technical architects and lead engineers to ensure that features are
            added in a scalable manner with minimal technical debt
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Coordinate with other engineering teams to maintain cross functional relationships
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Work towards increasing developer productivity by identifying and fixing bottlenecks
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Work closely with the Product team to build the product roadmap
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Build and implement rigorous engineering processes to ensure teams are productive,
            projects are coordinated and technical and design specifications are written and
            documented
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Carry out project management tasks for the team and run sprint planning/retrospective
            meetings
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Support the growth of the engineers and tech leads building Hasura Cloud
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Contribute to defining and growing the engineering management culture at Hasura
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=8kshFfs2xmkG"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
  {
    id: "engineering-manager-cloud-infrastructure-security",
    question: "Engineering Manager - Cloud & Infrastructure Security",
    answer: (
      <StyledDesc2>
        Hasura.io is looking for an experienced Manager to lead and eventually build a team to own
        our Cloud & Infrastructure Security. The Hasura Cloud platform gives customers a fully
        managed, production ready GraphQL API as a service to help build modern apps faster. You’ll
        help grow and develop the part of our security program that ensures that the Hasura product
        infrastructure and company systems, networks and applications are appropriately secured.
        We're looking for someone who enables positive business and security outcomes through
        effective communications, strategic planning, and business collaboration.
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Coordinate and collaborate with our IT and Business Application partners to define
            security requirements and assess Hasura's business applications, endpoints, and
            infrastructure while embracing Zero Trust principles
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Develop and refine our vulnerability management strategy as well as execute on a roadmap
            of incremental improvements
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Provide security leadership and coaching to our engineers to help develop and advance
            our people professionally
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Build, develop and maintain healthy cross-organizational relationships to cultivate good
            security outcomes for our customers and for Hasura business
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Partner with product engineering teams to help secure product workloads (Servers,
            Kubernetes, etc.) in our multi-cloud (AWS, GCP, Azure) environment
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Design and develop information security policies and associated controls to ensure we
            continue to meet GDPR/Privacy & compliance objectives as well as customer requirements
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Coordinate various compliance audits (SOC2, ISO etc.) with different teams and external
            stakeholders
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Develop and regularly report on the status of major initiatives under the team's charter
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Contribute to defining and growing the engineering management culture at Hasura
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=Ed-Ibd_1as0e"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
  {
    id: "technical-account-manager-india-singapore",
    question: "Technical Account Manager (India/Singapore)",
    answer: (
      <StyledDesc2>
        Hasura is looking for an experienced Technical Account Manager (TAM) who can help our
        customers/users get productive with Hasura and achieve their desired objectives. You will
        help enterprise customers chalk out their short term and long term Hasura adoption roadmap
        and objectives, and help them achieve success against this roadmap. You will do this by
        co-designing technical strategies with the Customer Success and other teams at Hasura, and
        execute this strategy to help onboard our Enterprise customers and partner with them to help
        them achieve their success milestones.
        <br />
        Your technical acumen and customer-facing skills will enable you to effectively represent
        Hasura within a customer’s environment. Internally, you will serve as the technical champion
        for our enterprise customers, acting as the voice of the customer within Hasura, and liaise
        with the sales, product and documentation teams to surface necessary changes.
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Take complete ownership of all post-sales engagements for a portfolio of accounts,
            ensuring constant communication with customers and emerging as their trusted technical
            advisor, with a special focus on onboarding new Enterprise customers to help them
            quickly get productive with the Hasura Enterprise edition product.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Understand, advocate and document customer’s use case, architecture and roadmap.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Effectively manage the tracking and resolution of customer escalations.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Champion and advocate for customer use-cases & requirements within Hasura. Articulate
            and follow up with other teams on customers’ product features and future roadmap needs
            and address them actively.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Prepare and/or curate necessary customer communications collateral such as training
            material, solution presentations, etc.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Report performance against key customer engagement metrics, including renewal/churn
            indicators, expansion and adoption trends through effective dashboards.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Coordinate with the Customer Success, Product, Engineering and other teams at Hasura for
            all of the above activities as required.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Track customer feedback, satisfaction and early-warnings for churn.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            <span>
              KPIs:
              <ul className="descItem">
                <li>Account renewals.</li>
                <li>Account expansion.</li>
              </ul>
            </span>
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=0ObciRFSakpf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
  {
    id: "senior-data-engineer-product-analytics-remote-india",
    question: "Senior Data Engineer - Product Analytics (Remote , India)",
    answer: (
      <StyledDesc2>
        GraphQL is changing the way developers & teams build software today. Hasura GraphQL Engine
        is an open source tool that makes it fast and easy to compose a GraphQL API for secure data
        access across a range of data sources and existing GraphQL and REST APIs. This approach
        empowers application developers to rapidly create and iterate their applications thanks to
        the flexibility of GraphQL and automation of toilsome considerations like security,
        performance, resource footprint, and scalability.
        <br />
        <br />
        Hasura is being adopted by developers in startups as well as some of the largest Fortune 500
        companies. We are excited to be at the intersection of two major ecosystem trends that will
        have a profound impact on development workflows going forward and we are looking for amazing
        people who are as excited as we are to join us on this journey!
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Work with data analysts and other data users to update data tools, queries, schemas, and
            notebooks
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Create and maintain data pipelines between internal databases and SaaS applications
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Maintain our data warehouse and ensure quality data availability
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Update instrumentation in various systems, either directly or working with other
            engineers
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=GdtG8RSx7cw1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
  {
    id: "site-reliability-engineer-sre-hasura-cloud-remote-india",
    question: "Site Reliability Engineer (SRE) - Hasura Cloud (Remote India)",
    answer: (
      <StyledDesc2>
        Hasura Cloud is a unique GraphQL product that lessens the effort that goes into building
        backends for applications. Our customers can use Hasura Cloud to generate a fully-featured
        unified GraphQL API connected to several databases and other REST/GraphQL APIs.
        <br />
        <br />
        Site Reliability Engineers (SREs) are responsible for keeping Hasura Cloud systems running
        smoothly and making sure updates can be rolled out reliably without any downtime.
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Build out our infrastructure with Terraform, Kubernetes, VMs.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Design, build and maintain core infrastructure pieces that allow Hasura Cloud scaling to
            support thousands of concurrent requests from our users.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Expand Hasura Cloud to support multiple Cloud providers, we currently use AWS and GCP.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Improve the deployment process to make it as reliable and boring as possible.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Be on a PagerDuty rotation to respond to Hasura Cloud availability incidents and provide
            support for service engineers with customer incidents.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Use your dev time to address the systemic issues you’ve identified, to proactively
            prevent incidents from happening.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Design smart monitoring that alerts on symptoms (our SLIs) rather than on causes, to
            make each alert meaningful and actionable.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Document every action so your findings turn into repeatable actions–and then into
            automation.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Debug production issues across services and levels of the stack.
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Plan the growth of Hasura Cloud's infrastructure.
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=ldltAERY0-CX"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
  {
    id: "senior-product-manager-hasura-console-and-cli",
    question: "Senior Product Manager - Hasura Console and CLI",
    answer: (
      <StyledDesc2>
        Hasura is looking for an exceptional Product Manager to lead product efforts for the Hasura
        Console and CLI.
        <br />
        <br />
        GraphQL is changing the way developers & teams build software today. The Hasura GraphQL
        Engine is an open source tool that makes it fast and easy to compose a GraphQL API for
        secure data access across a range of data sources and existing GraphQL and REST APIs. This
        approach empowers application developers to rapidly create and iterate their applications
        thanks to the flexibility of GraphQL and automation of toilsome considerations like
        security, performance, resource footprint, and scalability.
        <br />
        <br />
        In this position, you will work on developing innovative features in Hasura’s console and
        CLI such as creating a CMS experience for data management, managing database migrations and
        building an optimal API explorer.
        <br />
        <br />
        Hasura is being adopted by developers in startups as well as some of the largest Fortune 500
        companies. We are excited to be at the intersection of two major ecosystem trends that will
        have a profound impact on development workflows going forward and we are looking for amazing
        people who are as excited as we are to join us on this journey!
        <br />
        <br />
        <ul>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Lead product initiatives for various aspects of the Hasura Console and CLI
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Collaborate with customers and the Hasura GraphQL open-source community towards defining
            use cases and requirements for product improvements and features
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Develop a deep understanding of the market and competitive landscape
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Develop, maintain and present a product roadmap to customers, prospects and various
            internal stakeholders as needed
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Make data-driven decisions to drive the best possible customer experience
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Closely collaborate with members of the product management and engineering leadership
            team to drive organizational success
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Work with multiple engineering teams to execute on and deliver high-quality product
            releases
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Collaborate closely with marketing on all aspects of product launches
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Enable GTM teams on product value, competitive positioning and technical configurations
          </li>
          <li>
            <Icon variant="check_mark" color="sky_80" size="sm" />
            Work closely with management towards defining the company’s product strategy
          </li>
        </ul>
        <br />
        <a
          className="readMore"
          href="https://hasura.io/careers/?jobId=GVT1xQRCy4j4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More <Icon variant="chevron_right" color="sky_80" size="sm" />
        </a>
      </StyledDesc2>
    ),
  },
];

const JobList = () => {
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledJobsListWrapper>
          <StyledDesc1 fontWeight="font_600" linkVariant="grey_100">
            <Link className="back" to="/events/hasura-con-2022/" onClick={() => scrollToTop()}>
              <Icon className="mr-6" variant="arrow_left" color="sky_80" size="sm" />
              Back to Homepage
            </Link>
          </StyledDesc1>
          <StyledTitle4>HasuraCon 2022 Jobs</StyledTitle4>
          <StyledSubTitle2 className="hasConJobTitle">Join the Netlify Team</StyledSubTitle2>
          <FaqWrapper faqDetailState={netlifyJobsState} hasConJob={true} />
          <StyledSubTitle2 className="hasConJobTitle">Join the Hasura Team</StyledSubTitle2>
          <FaqWrapper faqDetailState={hasuraJobsState} hasConJob={true} />
        </StyledJobsListWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default JobList;
